<template>
  <main>
    <PageTitle>Fehler</PageTitle>
    <div class="container">
      <h3>Üps - das haben wir nicht geplant!</h3>
      <router-link class="link" to="/">Zurück zur Startseite</router-link>
    </div>
  </main>
</template>

<script>
import { visualMixin } from "@/mixins/visualMixin.js";
import PageTitle from "@/components/PageTitle.vue";
import { incrementComponentsLoading } from "@/events";

export default {
  mixins: [visualMixin],

  components: {
    PageTitle
  },

  beforeRouteLeave(to, from, leave) {
    this.$emit(incrementComponentsLoading);
    leave();
  }
};
</script>

<style scoped>
.container {
  min-height: 50vh;
  grid-column: 1 / -1;
  padding-top: 10vh;
}

h3 {
  margin: 0 60px 10vh;
}

.link {
  z-index: 0;
}
</style>

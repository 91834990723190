<template>
  <header class="headline-container">
    <h1 class="headline text--z-depth-4">
      <slot></slot>
    </h1>
  </header>
</template>

<script>
import { visualMixin } from "@/mixins/visualMixin.js";

export default {
  mixins: [visualMixin]
};
</script>

<style scoped>
.headline-container {
  position: relative;
  text-align: right;
  width: auto;
  grid-row: 1;
}

.headline {
  display: inline-block;
  font-size: 1rem;
  position: relative;
}

.headline::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #626262;
}

@media screen and (min-width: 360px) {
  .headline {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 600px) {
  .headline {
    font-size: 2rem;
  }
}

@media screen and (min-width: 768px) {
  .headline-container {
    grid-column: 2 / 4;
    padding: 0 24px;
  }

  .headline {
    padding: 0 12px;
    font-size: 3rem;
  }
}
</style>
